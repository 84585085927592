(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name informacio.image.controller:ImageCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament.informacio.image')
  .controller('ImageCtrl', ImageCtrl);

  function ImageCtrl() {
    var vm = this;
    vm.ctrlName = 'ImageCtrl';
  }
}());
